import { Link as RouterLink, Outlet } from 'react-router-dom';

function layout() {
	return (
		<>
			<Outlet />
		</>
		);
}

export default layout;