import { useEffect } from 'react';

// Component
import Footer from '../layout/footer';

// Image
import alurRegistrasi from '../assets/img/alur_registrasi_online.jpg';

function Register() {

	useEffect(() => {
		document.title = "E-ISH | Prosedur Registrasi"
	}, []);

	return (
		<>

		<div className="main clearfix">

			<div className="prosedur-register-banner col-lg-12 d-flex">
				<div className="container mx-auto my-auto col-sm-12 fix-float-left">
					<h3>ALUR REGISTRASI ONLINE</h3>
					<h3>ELEKTRONIK - IZIN SENJATA API DAN BAHAN PELEDAK (E-ISH)</h3>
					<p>E-ISH adalah sistem informasi online yang membantu pihak kepolisian dan masyarakat sipil yang berkepentingan dengan perijinan senjata api dan bahan peledak dalam memproses administrasi permohonan perijinan senjata api dan bahan peledak menjadi lebih baik, cepat, efisien dan transparan</p>
				</div>
			</div>

			<div className="prosedur-register-main col-lg-12">
				<img src={alurRegistrasi} alt="registration flow"/>
			</div>

			<div className="prosedur-dokumen ptb-64px col-lg-12">
				<div className="container clearfix">
					<h3>File dokumen yang harus dipersiapkan sebelum melakukan registrasi :</h3>
					<ul className="clearfix prosedur-dokumen-list">
						<li className="prosedur-dokumen-item col-lg-6 col-md-12">
							<div className="dokumen-list-container">
								<h4>
									<strong>Perseorangan</strong>
								</h4>
								<ol>
									<li>Kartu Tanda Penduduk (KTP)</li>
									<li>Kartu Keluarga (KK)</li>
									<li>Akte Kelahiran</li>
								</ol>
							</div>
							<p className="filenote">* File dokumen harus berformat (.jpg, .png atau .pdf) dengan besar &lt; 1mb</p>
						</li>
						<li className="prosedur-dokumen-item col-lg-6 col-md-12">
							<div className="dokumen-list-container">
								<h4>
									<strong>Instansi</strong>
								</h4>
								<ol>
									<li>Nomor Pokok Wajib Pajak (NPWP) Badan Usaha</li>
									<li>Akte Pendirian Perusahaan</li>
									<li>Surat Izin Usaha Perdagangan (SIUP)</li>
									<li>Surat Izin Usaha Industri (SIUI)</li>
									<li>Tanda Daftar Perusahaan (TDP)</li>
								</ol>
							</div>
							<p className="filenote">* File dokumen harus berformat (.jpg, .png atau .pdf) dengan besar &lt; 1mb</p>
						</li>
					</ul>
				</div>
			</div>

		</div>
    	<Footer />
    	</>
		);
	
}

export default Register;