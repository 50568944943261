import { useEffect } from 'react';

// Component
import Footer from '../layout/footer';

// Image
import landasanSubImg from '../assets/img/icon_landasanhukum.svg';

function Landasan() {

	const docLink = [
		'perkap_no_18_th_2015',
		'perkap_no_8_th_2012',
		'perkap_no_11_th_2017',
		'perkap_no_5_th_2018',
		'skep_no_82_th_2004'
	];

	useEffect(() => {
		document.title = "E-ISH | Landasan Hukum"
	}, []);

	return (
		<>
		<div className="main clearfix">

			<div className="landasan-banner col-lg-12 d-flex">
				<div className="container mx-auto my-auto col-sm-12 fix-float-left">
					<h3>Landasan Hukum</h3>
				</div>
			</div>

			<div className="landasan-sub-banner">
				<div className="container">
					<div className="landasan-sub-img col-lg-2" >
						<img src={landasanSubImg}/>						
					</div>
					<div className="col-lg-10 col-sm-12">
						<h4>Landasan hukum</h4>
						<p>Warga sipil boleh memiliki senjata api sebagai alat pertahanan diri asalkan sesuai dengan syarat dan ketentuan yang sudah ditetapkan Kepolisian Republik Indonesia (POLRI) dan diizinkan dengan alasan hukum</p>						
					</div>
				</div>
			</div>

			<div className="landasan-content">
				<div className="container">
					<h4>Landasan hukum yang mengatur tentang perizinan, pengawasan, dan pengendalian senjata api</h4>
					<ol>
						<li>
							PERATURAN KEPALA KEPOLISIAN NEGARA REPUBLIK INDONESIA NOMOR 18 THN 2015 TTG PERIZINAN, PENGAWASAN DAN PENGENDALIAN SENJATA API NONORGANIK KEPOLISIAN NEGARA REPUBLIK INDONESIA/ TENTARA NASIONAL INDONESIA UNTUK KEPENTINGAN BELA DIRI
							&emsp;<a href={'/landasan/'+docLink[0]}>Lihat Selengkapnya ...</a>
						</li>
						<li>
							PERATURAN KEPALA KEPOLISIAN NEGARA REPUBLIK INDONESIA NOMOR 8 TAHUN 2012 TENTANG PENGAWASAN DAN PENGENDALIAN SENJATA API UNTUK KEPENTINGAN OLAHRAGA
							&emsp;<a href={'/landasan/'+docLink[1]}>Lihat Selengkapnya ...</a>
						</li>
						<li>
							PERATURAN KEPALA KEPOLISIAN NEGARA REPUBLIK INDONESIA NOMOR 11 TAHUN 2017 TENTANG PERIZINAN, PENGAWASAN DAN PENGENDALIAN SENJATA API NONORGANIK TENTARA NASIONAL INDONESIA/KEPOLISIAN NEGARA REPUBLIK INDONESIA DAN PERALATAN KEAMANAN YANG DIGOLONGKAN SENJATA API BAGI PENGEMBAN FUNGSI KEPOLISIAN LAINNYA
							&emsp;<a href={'/landasan/'+docLink[2]}>Lihat Selengkapnya ...</a>
						</li>
						<li>
							PERATURAN KEPOLISIAN NEGARA REPUBLIK INDONESIA NOMOR 5 TAHUN 2018 TENTANG PENGAWASAN DAN PENGENDALIAN REPLIKA SENJATA JENIS AIRSOFT GUN DAN PAINTBALL
							&emsp;<a href={'/landasan/'+docLink[3]}>Lihat Selengkapnya ...</a>
						</li>
						<li>
							SURAT KEPUTUSAN KAPOLRI NO. POL. : SKEP / 82 / II /2004 TENTANG BUKU PETUNJUK PELAKSANAAN PENGAWASAN DAN PENGENDALIAN SENJATA API NON ORGANIK TNI / POLRI
							&emsp;<a href={'/landasan/'+docLink[4]}>Lihat Selengkapnya ...</a>
						</li>					
					</ol>
				</div>				
			</div>

		</div>
    	<Footer />
    	</>
		);
	
}

export default Landasan;