import axios from "axios";
import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

// Component
import ShortPost from '../page/shortPost';

// Image
import iconPrev from '../assets/img/icon_prev.svg';
import iconNext from '../assets/img/icon_next.svg';

function PostList() {
	const [post, setPost] = useState([]);
	const [pcWidth, setPcWidth] = useState(0);
	const [postWidth, setPostWidth] = useState(0);
	const [leftMargin, setLeftMargin] = useState(0);
	const [slItem, setSlItem] = useState({
		'si': 1,
		'cs': 1,
		'ips': 1
	});
	const { height, width } = useWindowDimensions();
	let bannerHeight = height;
	
	useEffect(() => {
		document.title = "E-ISH"

		axios({
			method: 'GET',
			// url: global.config.sourceurl+'/wp/v2/posts?categories=2&filter[orderby]=ID&order=asc'
			url: global.config.sourceurl+'/wp/v2/posts?_embed&filter[orderby]=ID&order=desc'
		}).then((res) => {
			const result = res.data;
			setPost([result]);

			if(parseInt(width)<=576) {
				setPcWidth(result.length*100);
				setSlItem({'si': result.length, 'cs': slItem.cs, 'ips': 1});
			} else {
				setPcWidth(result.length*50);
				setSlItem({'si': result.length, 'cs': slItem.cs, 'ips': 2});
			}
			setPostWidth(100/result.length);

		});

	}, []);

	function handlePrev(e) {
		e.preventDefault();
		let lm = 0;
		if(slItem.cs>1) {

			if(parseInt(width)<=576) {
				lm = 100;
			} else {
				lm = 50;
			}
			setLeftMargin(leftMargin+lm);
			setSlItem({'si': slItem.si, 'cs': slItem.cs-1, 'ips': slItem.ips});
		}
	}

	function handleNext(e) {
		e.preventDefault();
		let lm = 0;
		if(slItem.cs<(slItem.si/slItem.ips)) {

			if(parseInt(width)<=576) {
				lm = 100;
			} else {
				lm = 50;
			}
			setLeftMargin(leftMargin-lm);
			setSlItem({'si': slItem.si, 'cs': slItem.cs+1, 'ips': slItem.ips});

		}
	}

	if(post.length>0) {
		if(parseInt(width)<=576) {
			bannerHeight = 'auto';
		}
		return (
		<>
			<div className="home-post-list col-lg-12">
				<h3>Informasi / Berita</h3>
				<a href="#" onClick={handlePrev} className="slider-nav nav-prev">
					<img src={iconPrev} />
				</a>
				<a href="#" onClick={handleNext} className="slider-nav nav-next">
					<img src={iconNext} />
				</a>
				<div className="container post-container clearfix">
					<div className="post-slide clearfix" style={{width: pcWidth+'%', marginLeft: leftMargin+'%'}}>
						{post[0].map((item) => (
						<ShortPost
						key={item.id}
						base={item}
						postWidth={postWidth}
						/>
						))}
					</div>
				</div>
			</div>
    	</>
		);
	} else {
		return (
		<>
			<div className="home-post-list col-lg-12">
				<div className="main-content d-flex" style={{minHeight: '100%', minWidth: '100%'}}>
					<div className="mx-auto my-auto">
						<div className="spinner-border"></div>
					</div>
				</div>
			</div>
		</>
		);
	}
}

export default PostList;