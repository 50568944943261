import { useEffect } from 'react';

// Component
import Footer from '../layout/footer';

function Prosedur() {
	const url = new URL(window.location);
	const docUrl = url.pathname.replaceAll('/', '').replace('prosedur', '');
	const doc = '../doc/'+docUrl+'.png';

	useEffect(() => {
		document.title = "E-ISH | Prosedur Registrasi"
	}, []);

	return (
		<>

		<div className="main clearfix">

			<div className="prosedur-register-banner col-lg-12 d-flex">
				<div className="container mx-auto my-auto col-sm-12 fix-float-left">
					<h3>PROSES PERIZINAN</h3>
				</div>
			</div>

			<div className="prosedur-detail-main col-lg-12">
				<div className="container">
					<img src={doc} alt="process flow"/>
				</div>
			</div>

		</div>
    	<Footer />
    	</>
		);
	
}

export default Prosedur;