import { useEffect } from 'react';

// Component
import Footer from '../layout/footer';

function Ketentuan() {

	useEffect(() => {
		document.title = "E-ISH | Syarat & Ketentuan"
	}, []);

	return (
		<>
		<div className="main clearfix">

			<div className="privasi-banner col-lg-12 d-flex">
				<div className="container mx-auto my-auto col-sm-12 fix-float-left">
					<h3>Syarat dan Ketentuan</h3>
				</div>
			</div>

			<div className="privasi-content">
				<div className="container">

					<p className="text-title">Informasi Umum</p>
					<p>Website E-ISH adalah website yang memuat konten-konten infromasi yang berhubungan dengan produk, fitur, dan layanan dari Sistem Informasi
					Elektronik – Izin Senjata Api dan Bahan Peledak (E-ISH). Situs web <a href="https://e-ish.com">https://e-ish.com</a> dioperasikan oleh tim TI dari E-ISH. Semua pengunjung
					dan pengguna situs web E-ISH harus menerima semua persyaratan yang telah dikeluarkan.</p>


					<p className="text-title">Penerimaan Kebijakan Penggunaan</p>
					<p>Harap baca semua poin di bagian "Kebijakan Penggunaan" ini dengan seksama sebelum menggunakan <a href="https://e-ish.com">https://e-ish.com</a>. Persyaratan ini berlaku
					untuk semua pengunjung dan pengguna yang mengakses atau menggunakan layanan yang terdapat di situs web E-ISH. Dengan mengakses
					atau menggunakan layanan di situs web E-ISH, Anda dianggap mengetahui semua kebijakan dan setuju untuk terikat oleh Persyaratan ini.
					Jika Anda tidak setuju dengan bagian dari persyaratan mana pun, harap batalkan penggunaan situs web E-ISH karena Anda mungkin tidak
					dapat mengakses layanan yang tersedia didalamnya.</p>

					<p className="text-title">Syarat Penerimaan Pembaruan Kebijakan Penggunaan</p>
					<p>Jika ada koreksi, tambahan atau pengurangan ketentuan penggunaan situs web E-ISH, Kami akan memberitahukan mengenai perubahan tersebut
					kepada pengguna melalui email atau media lainnya. Semua pengguna harus membaca dan memahami pembaruan apa pun dari persyaratan
					ini dan menerimanya. Jika pengguna tidak stuju pada perubahan tersebut, pengguna harus berhenti mengunjungi dan menggunakan
					layanan yang terdapat di situs web E-ISH.</p>

					<p className="text-title">Hak Kekayaan Intelektual</p>
					<p>Seluruh konten yang terdapat di situs web E-ISH adalah milik tim pengembang E-ISH. Seorang pengguna tidak diperbolehkan menerbitkan,
					menyalin, atau membuat duplikasi tanpa persetujuan tertulis yang ditujukan dan disahkan oleh pihak E-ISH. Jika ditemukan adanya pelanggaran
					hukum atas ketentuan hak kekayaan intelektual, maka akan ditindaklanjuti oleh pihak E-ISH.</p>

					<p className="text-title">Tanggung Jawab Hukum</p>
					<p>Pihak E-ISH tidak bertanggung jawab atas segala pelanggaran hukum yang dilakukan oleh pengunjung dan pengguna saat menggunakan
					layanan situs web E-ISH yang diproses oleh pengunjung dan pengguna tersebut.</p>

					<p className="text-title">Keaslian Informasi</p>
					<p>Seorang pengguna harus memberikan informasi yang otentik, benar dan bertanggung jawab terkait dengan penggunaan layanan yang terdapat
					di situs web E-ISH. Kami tidak bertanggung jawab atas segala hal yang bisa saja terjadi karena informasi yang salah atau tidak benar.</p>

					<p className="text-title">Keamanan dan Kerahasiaan Informasi Pribadi</p>
					<p>Setiap informasi yang telah dikirimkan oleh pengguna selama penggunaan layanan dilindungi berdasarkan Kebijakan Privasi Kami. Pengguna
					harus membaca seluruh konten dokumen Kebijakan Privasi yang Kami diposting di situs ini. Pastikan Anda menerima semua poin sebelum
					mulai menggunakan layanan yang Kami sediakan.</p>

					<p className="text-title">Umpan Balik dari Pengguna</p>
					<p>Segala masukan yang berhubungan dengan E-ISH, seperti keluhan malfungsi, laporan kesalahan, saran untuk pengembangan lebih lanjut, dll
					dapat dialamatkan ke halaman "hubungi kami" di website.</p>

					<p className="text-title">Tautan ke Pihak Ketiga</p>
					<p>Layanan kami mungkin berisi tautan ke situs web atau layanan pihak ketiga yang tidak dimiliki atau dikendalikan oleh E-ISH. Kami tidak memiliki
					kendali atas website tersebut dan tidak bertanggung jawab atas konten, kebijakan privasi, atau praktik dari situs web atau layanan pihak
					ketiga manapun. Anda selanjutnya mengetahui dan menyetujui bahwa pihak E-ISH tidak bertanggung jawab baik secara langsung maupun tidak
					langsung atas kerusakan atau kerugian yang disebabkan atau diduga disebabkan oleh atau sehubungan dengan penggunaan atau kepercayaan
					Anda pada konten, fitur, layanan, barang atau jasa yang tersedia pada atau melalui situs web eksternal tersebut atau layanan semacam itu.
					Kami sangat menyarankan Anda untuk membaca syarat dan ketentuan dan kebijakan privasi dari situs web pihak ketiga atau layanan yang
					Anda kunjungi.</p>

					<p className="text-title">Penghentian Layanan</p>
					<p>Kami dapat menghentikan atau menangguhkan akses ke layanan Kami segera, tanpa pemberitahuan atau pertanggungjawaban sebelumnya,
					dengan alasan apapun, termasuk namun tidak terbatas jika Anda melanggar kebijakan-kebijakan yang terdapat di situs E-ISH.
					Penegakan Peraturan Pemerintah
					Kebijakan Penggunaan ini diatur dan ditafsirkan sesuai dengan hukum yang berlaku di Negara Kesatuan Republik Indonesia, tanpa memperhatikan
					pertentangan ketentuan hukumnya. Kegagalan Kami untuk menegakkan hak atau ketentuan yang terdapat pada kebijakan ini tidak akan
					dianggap sebagai pengabaian hak-hak tersebut. Jika ada ketentuan dalam kebijakan ini yang dianggap tidak sah atau tidak dapat diterapkan
					oleh pengadilan, sisa ketentuan dalam kebijakan ini akan tetap berlaku.</p>

					<p className="text-title">Perubahan Kebijakan</p>
					<p>Kami sewaktu ke waktu dapat melakukan perubahan dan atau pembaharuan pada Syarat Penggunaan Kami tanpa pemberitahuan ke Anda sebelumnya.
					Oleh karena itu, Anda bertanggung jawab untuk mengakses informasi tersebut secara berkala untuk mendapatkan perubahan dan
					atau pembaharuan terakhir.</p>

					<p className="text-title">Persetujuan dan Perubahan Syarat Penggunaan</p>
					<p>Dengan menggunakan Sistem Infromasi Kami, Pengguna dengan ini menyetujui syarat-syarat penggunaan sesuai dengan ketentuan-ketentuannya.
					Jika Anda tidak menyetujui persyaratan baru tersebut, mohon berhenti menggunakan layanan E-ISH</p>
					
					<p className="text-title">Penyelesaian Perselisihan</p>
					<ol>
						<li>Syarat Penggunaan ini dan pelaksanaannya diatur oleh dan tunduk pada hukum Negara Kesatuan Republik Indonesia.</li>
						<li>Para Pihak sepakat untuk menyelesaikan setiap perselisihan sehubungan dengan Kebijakan Penggunaan ini secara musyawarah mufakat.</li>
						<li>Apabila perselisihan tidak dapat diselesaikan secara musyawarah mufakat dalam waktu 90 (sembilan puluh) Hari Kalender sejak
						diterimanya pemberitahuan tertulis oleh salah satu Pihak dari Pihak lainnya tentang timbulnya perselisihan, maka perselisihan dimaksud akan
						diselesaikan melalui hukum yang berlaku di Negara Kesatuan Republik Indonesia.</li>
					</ol>

					<p className="text-title">Kontak Kami</p>
					<p>Jika ada keraguan atau pertanyaan tentang Kebijakan Penggunaan ini, silahkan hubungi Kami di <a href="mailto:supporthelp@eish.com">supporthelp@eish.com</a>.
					Kebijakan Penggunaan ini diperbarui pada: 23 Mei 2021 dan akan berlaku kepada seluruh Pengguna situs web E-ISH bersamaan dengan
					tanggal tersebut.</p>

					<p className="text-title">Disclaimer Elektronik - Izin Senjata Api Dan Bahan Peledak (E-ISH)</p>
					<p>Jika Anda memerlukan informasi lebih lanjut atau memiliki pertanyaan tentang disclaimer situs yang Kami kelola, jangan ragu untuk menghubungi
					Kami melalui email di <a href="mailto:supporthelp@eish.com">supporthelp@eish.com</a>.</p>

					<p>Semua informasi di situs web ini diterbitkan dengan itikad baik dan hanya untuk tujuan informasi umum yang berkaitan dengan Elektronik –
					Izin Senjata Api dan Bahan Peledak (E-ISH). <a href="https://e-ish.com">https://e-ish.com</a> tidak membuat jaminan apa pun tentang kelengkapan, keandalan, dan
					keakuratan informasi ini. Segala tindakan yang Anda lakukan atas informasi yang Anda temukan di situs web ini (<a href="https://e-ish.com">https://e-ish.com</a>),
					sepenuhnya menjadi tanggung jawab Anda sendiri dan Anda siap untuk menerima resiko apapun yang akan timbul di kemudian hari. Kami tidak
					akan bertanggung jawab atas kerugian dan / atau kerusakan sehubungan dengan penggunaan situs web kami.</p>

					<p>Dari situs web kami, Anda mungkin dapat mengunjungi situs web lain yang tidak kami kelola dengan mengikuti hyperlink ke situs eksternal
					tersebut. Meski Kami telah berusaha untuk menyediakan tautan website-website paling terpercaya di bidangnya, konten website eksternal
					tersebut sepenuhnya berada di bawah kendali pemilik website tersebut. Informasinya dapat berubah sebelum tim E-ISH sempat mengganti
					atau menghapusnya.</p>

					<p>Oleh karena itu, Kami akan segera mengganti atau menghapus informasi maupun tautan yang terbukti salah dan berbahaya bagi data pribadi
					Anda. Bantuan Anda dalam bentuk komentar atau email tentang permasalahan tersebut, akan kami terima dengan senang hati. Perlu Anda
					ketahui juga bahwa website-website eksternal tersebut juga memiliki terms of service (TOS) dan kebijakan privasi tersendiri, sehingga Kami
					tidak meiliki kendali lagi terhadap situs eksternal yang Anda akses. Kami menganjurkan Anda untuk membaca dokumen - dokumen terkait di
					website tersebut sebelum mengunggah informasi apapun.</p>

					<p className="text-title">Persetujuan</p>
					<p>Dengan menggunakan situs web Kami, Anda dengan ini menyetujui seluruh ketentuan disclaimer, ketentuan kebijakan privasi, dan ketentuan
					penggunaan layanan yang kami miliki. Bila Anda menemui kesalahan dikemudian hari, silahkan hubungi layanan pelanggan Kami. Kami dengan
					senang hati akan melakukan koreksi atas kesalahan Kami.</p>

					<p className="text-title">Pembaruan</p>
					<p>Disclaimer ini terakhir diperbarui pada: 23 Mei 2021 dan jika kami memperbarui, mengubah, atau membuat perubahan apa pun pada dokumen
					ini, perubahan tersebut akan diposting secara jelas di sini.</p>
				
				</div>				
			</div>

		</div>
    	<Footer />
    	</>
		);
	
}

export default Ketentuan;