import { useEffect } from 'react';

// Component
import Footer from '../layout/footer';

function Kontak() {

	useEffect(() => {
		document.title = "E-ISH | Kontak Kami"
	}, []);

	return (
		<>
		<div className="main clearfix">

			<div className="privasi-banner col-lg-12 d-flex">
				<div className="container mx-auto my-auto col-sm-12 fix-float-left">
					<h3>Kontak Kami</h3>
				</div>
			</div>

			<div className="privasi-content">
				<div className="container">

					<ul className="contact-list">

						<li>
							<div className="contact-detail">
								<h5>POLDA ACEH</h5>
								<p>Jl. Cut Meutia No 25 Banda Aceh</p>
								<p>Telp : 0651-29556</p>
								<p>Website : <a href="https://aceh.polri.go.id/" target="_blank" rel="noreferrer">https://aceh.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA BALI</h5>
								<p>Jl. WR Supratman No 7 Denpasar</p>
								<p>Telp : 0361-227174</p>
								<p>Website : <a href="http://www.bali.polri.go.id/" target="_blank" rel="noreferrer">http://www.bali.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA BANTEN</h5>
								<p>Jl. Syeh Nawawi Al Bantani No 76 Serang</p>
								<p>Telp : 0254-228454</p>
								<p>Website : <a href="https://banten.polri.go.id/" target="_blank" rel="noreferrer">https://banten.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA BENGKULU</h5>
								<p>Jl. Adam Malik Km 9 Bengkulu</p>
								<p>Telp : 0736-51041</p>
								<p>Website : <a href="https://bengkulu.polri.go.id/" target="_blank" rel="noreferrer">https://bengkulu.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA DI YOGYAKARTA</h5>
								<p>Jl. Lingkar Utama Condong Catur Yogyakarta</p>
								<p>Telp : 0274-885009</p>
								<p>Website : <a href="https://jogja.polri.go.id/" target="_blank" rel="noreferrer">https://jogja.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA GORONTALO</h5>
								<p>Jl. Limboto Raya No 17 Gorontalo</p>
								<p>Telp : 0435-838536</p>
								<p>Website : <a href="https://gorontalo.polri.go.id/" target="_blank" rel="noreferrer">https://gorontalo.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA JAMBI</h5>
								<p>Jl. Jend Sudirman No 45 Jambi</p>
								<p>Telp : 0741-22025</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA JAWA BARAT</h5>
								<p>Jl. Soekarno Hatta No 748 Bandung</p>
								<p>Telp : 022-7800005</p>
								<p>Website : <a href="https://jabar.polri.go.id/" target="_blank" rel="noreferrer">http://jabar.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA JAWA TENGAH</h5>
								<p>Jl. Pahlawan No 1 Semarang</p>
								<p>Telp : 024-8413044</p>
								<p>Website : <a href="https://jateng.polri.go.id/" target="_blank" rel="noreferrer">http://jateng.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA JAWA TIMUR</h5>
								<p>Jl. A. Yani No 116 Surabaya</p>
								<p>Telp : 031-8280333</p>
								<p>Website : <a href="https://jatim.polri.go.id/" target="_blank" rel="noreferrer">http://jatim.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA KALIMANTAN BARAT</h5>
								<p>Jl. A. Yani No 1 Pontianak</p>
								<p>Telp : 0561-734004</p>
								<p>Website : <a href="https://kalbar.polri.go.id/" target="_blank" rel="noreferrer">https://kalbar.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA KALIMANTAN SELATAN</h5>
								<p>Jl. S. Parman No 16 Banjarmasin</p>
								<p>Telp : 0511-3354876</p>
								<p>Website : <a href="https://kalsel.polri.go.id/" target="_blank" rel="noreferrer">https://kalsel.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA KALIMANTAN TENGAH</h5>
								<p>Jl. Tjilik Riwut Km 1 Palangkaraya</p>
								<p>Telp : 0536-3221720</p>
								<p>Website : <a href="https://kalteng.polri.go.id/" target="_blank" rel="noreferrer">https://kalteng.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA KALIMANTAN TIMUR</h5>
								<p>Jl. Syarifuddin Yoes No 99 Balikpapan</p>
								<p>Telp : 0542-421220</p>
								<p>Website : <a href="https://kaltim.polri.go.id/" target="_blank" rel="noreferrer">https://kaltim.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA KALIMANTAN UTARA</h5>
								<p>Jalan Komjen Dr. H.M. Jasin No.86, Bumi Rahayu, Tj. Selor, Kabupaten Bulungan</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA KEPULAUAN BANGKA BELITUNG</h5>
								<p>Jl. Komplek Perkantoran Air Itam No 3 Pangkal Pinang</p>
								<p>Telp : 0717-437908</p>
								<p>Website : <a href="https://babel.polri.go.id/" target="_blank" rel="noreferrer">https://babel.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA KEPULAUAN RIAU</h5>
								<p>Jl. Hang Jebat Batu Besar, Nongsa, Batam</p>
								<p>Telp : 0778-7763541</p>
								<p>Website : <a href="https://kepri.polri.go.id/" target="_blank" rel="noreferrer">https://kepri.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA LAMPUNG</h5>
								<p>Jl. WR Supratman No 1 Bandar Lampung</p>
								<p>Telp : 0721-486832</p>
								<p>Website : <a href="https://lampung.polri.go.id/" target="_blank" rel="noreferrer">https://lampung.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA MALUKU</h5>
								<p>Jl. Rijali No 1 Ambon</p>
								<p>Telp : 0911-352912</p>
								<p>Website : <a href="https://maluku.polri.go.id/" target="_blank" rel="noreferrer">http://maluku.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA MALUKU UTARA</h5>
								<p>Jl. Kapitan Pattimura, Kalumpang, Ternate</p>
								<p>Telp : 0921-327045</p>
								<p>Website : <a href="https://malut.polri.go.id/" target="_blank" rel="noreferrer">https://malut.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA METRO JAYA</h5>
								<p>Jl. Jend Sudirman No 55 Jakarta Selatan</p>
								<p>Telp : 021-5234001</p>
								<p>Website : <a href="https://metro.polri.go.id/" target="_blank" rel="noreferrer">https://metro.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA NTB</h5>
								<p>Jl. Langko No 77 Mataram</p>
								<p>Telp : 0370-633152</p>
								<p>Website : <a href="https://ntb.polri.go.id/" target="_blank" rel="noreferrer">https://ntb.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA NTT</h5>
								<p>Jl. Soeharto No 3 Kupang</p>
								<p>Telp : 0380-833132</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA PAPUA</h5>
								<p>Jl. Dr Samratulangi No 8 Jayapura</p>
								<p>Telp : 0967-531014</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA PAPUA BARAT</h5>
								<p>JL. Pahlawan Sanggeng</p>
								<p>Telp : 0986-211253</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA RIAU</h5>
								<p>Jl. Jend Sudirman No 235 Pekanbaru</p>
								<p>Telp : 0761-31307</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA SULAWESI BARAT</h5>
								<p>Jl. Aiptu Nurman No.1</p>
								<p>Website : <a href="https://sulbar.polri.go.id/" target="_blank" rel="noreferrer">https://sulbar.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA SULAWESI SELATAN</h5>
								<p>Jl. Perintis Kemerdekaan Km 16 Makasar</p>
								<p>Telp : 0411-515101</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA SULAWESI TENGAH</h5>
								<p>Jl. Dr Samratulangi No 78 Palu</p>
								<p>Telp : 0451-421555</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA SULAWESI TENGGARA</h5>
								<p>Jl. Haluleo No 1 Kendari</p>
								<p>Telp : 0401-391555</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA SULAWESI UTARA</h5>
								<p>Jl. Bethesda No 62 Manado</p>
								<p>Telp : 0431-862019</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA SUMATRA BARAT</h5>
								<p>Jl. Sudirman No 55 Padang</p>
								<p>Telp : 0751-811234</p>
								<p>Website : <a href="https://sumbar.polri.go.id/" target="_blank" rel="noreferrer">https://sumbar.polri.go.id/</a></p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA SUMATRA SELATAN</h5>
								<p>Jl. Jend Sudirman Km 4,5 Palembang</p>
								<p>Telp : 0711-320550</p>
							</div>
						</li>
						<li>
							<div className="contact-detail">
								<h5>POLDA SUMATRA UTARA</h5>
								<p>Jl. SM Raja XII Km 10,5 No 60 Medan</p>
								<p>Telp : 061-7879363</p>
								<p>Website : <a href="https://sumut.polri.go.id/" target="_blank" rel="noreferrer">https://sumut.polri.go.id/</a></p>
							</div>
						</li>

					</ul>
				
				</div>				
			</div>

		</div>
    	<Footer />
    	</>
		);
	
}

export default Kontak;