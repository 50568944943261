import { useEffect } from 'react';

// Component
import Footer from '../layout/footer';

// Component
import {
	Accordion
} from 'react-bootstrap';

// Image

function Prosedur() {

	useEffect(() => {
		document.title = "E-ISH | FAQ"
	}, []);

	return (
		<>
		<div className="main clearfix">

			<div className="faq-banner col-lg-12 d-flex">
			</div>

			<div className="prosedur-mid faq-list col-lg-12">
				<div className="container">
					<h3>FAQs</h3>
					<Accordion defaultActiveKey="0">
						<Accordion.Item eventKey="0">
							<Accordion.Header>Q: Apa itu Elektronik – Izin Senjata Api dan Bahan Peledak (E-ISH)?</Accordion.Header>
							<Accordion.Body>
								<p><span className="bolded">E-ISH</span> adalah singkatan dari <span className="italicized">Elektronik – Izin Senjata Api dan Bahan Peledak.</span> E-ISH merupakan aplikasi atau sistem informasi online yang dirancang untuk mengorganisir dan mengatur peredaran senjata api non-organik dan bahan peledak yang akan dan atau sudah beredar di Negara Kesatuan Republik Indonesia; membantu tugas kepolisian dalam mengadministrasi senjata api dan bahan peledak yang akan dan atau sudah beredar di Negara Kesatuan Republik Indonesia; serta membantu pengguna umum dan atau perusahaan berbadan hukum yang terkait dalam melakukan perizinan yang berkaitan dengan senjata dan bahan peledak.</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header>Q: Apa manfaat penggunaan E-ISH bagi masyarakat?</Accordion.Header>
							<Accordion.Body>
								<p>lorem blah blah blah ...</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header>Q: Untuk Siapa Aplikasi Elektronik – Izin Senjata Api dan Bahan Peledak (E-ISH) diperuntukkan?</Accordion.Header>
							<Accordion.Body>
								<p>Elektronik – Izin Senjata Api dan Bahan Peledak (E-ISH) dapat digunakan oleh:</p>
								<ol>
									<li>
										<p>Masyarakat sipil yang be n perizinan senjata api dan bahan peledak, seperti:</p>
										<ol>
											<li>Masyarakat sipil yang ingin memiliki senjata api untuk bela diri</li>
											<li>Masyarakat sipil yang ingin memiliki senjata api untuk koleksi</li>
											<li>Masyarakat sipil yang merupakan bagian dari Perbakin (atlet olahraga menembak)</li>
										</ol>
									</li>
									<li>
										<p>Organisasi yang berkepentingan dengan perizinan senjata api dan bahan peledak, seperti:</p>
										<ol>
											<li>Perbakin (Fasilitas di lokasinya memungkinkan untuk melakukan kegiatan penggudangan senjata api)</li>
										</ol>
									</li>
									<li>
										<p>Perusahaan atau Instansi yang berkepentingan dengan perizinan senjata api dan bahan peledak, seperti:</p>
										<ol>
											<li>Perusahaan atau instansi berbadan hukum yang memiliki izin untuk melakukan kegiatan ekspor dan impor senjata api dan bahan peledak</li>
											<li>Perusahaan atau instansi berbadan hukum yang memiliki izin untuk melakukan kegiatan memproduksi senjata api dan bahan peledak</li>
											<li>Masyarakat sipil yang merupakan bagian dari Perbakin (atlet olahraga menembak)</li>
										</ol>
									</li>
									<li>
										<p>Masyarakat sipil yang tergabung di dalam sebuah perusahaan atau instansi dan dia memiliki tugas sebagai pengemban fungsi kepolisian lainnya</p>
									</li>
									<li>
										<p>Pihak kepolisian yang berkaitan dengan administrasi di perizinan senjata api dan bahan peledak.</p>
									</li>
								</ol>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header>Q: Perizinan seperti apa yang bisa ditangani aplikasi E-ISH?</Accordion.Header>
							<Accordion.Body>
								<p>lorem blah blah blah ...</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="4">
							<Accordion.Header>Q: Bagaimana cara saya mengakses aplikasi E-ISH?</Accordion.Header>
							<Accordion.Body>
								<p>lorem blah blah blah ...</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="5">
							<Accordion.Header>Q: Apakah aplikasi E-ISH hanya tersedia versi web saja?</Accordion.Header>
							<Accordion.Body>
								<p>lorem blah blah blah ...</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="6">
							<Accordion.Header>Q: Apa system requirement dari aplikasi E-ISH?</Accordion.Header>
							<Accordion.Body>
								<p>lorem blah blah blah ...</p>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="7">
							<Accordion.Header>Q: Bagaimana dengan pengguna (pemohon) lama yang sudah pernah melakukan pengurusan izin berkaitan dengan senjata api sebelum E-ISH diterapkan diseluruh kepolisian?</Accordion.Header>
							<Accordion.Body>
								<p>lorem blah blah blah ...</p>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
			</div>			

		</div>
    	<Footer />
    	</>
		);
	
}

export default Prosedur;