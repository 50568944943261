module.exports = global.config = {
	sourceurl: 'https://wp.mod.iixmedia.com/index.php/wp-json',
	headerHeight: 72,
	footerHeight: 88,
	narrowFooterHeight: 202,
	month: [
		'Januari',
		'Februari',
		'Maret',
		'April',
		'Mei',
		'Juni',
		'Juli',
		'Agustus',
		'September',
		'Oktober',
		'November',
		'Desember'
	]
};