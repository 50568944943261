import useWindowDimensions from '../hooks/useWindowDimensions';
import React, { useEffect } from 'react';

// Component
import Footer from '../layout/footer';

function NotFound() {
	const { height, width } = useWindowDimensions();
	let fixedHeight = height-global.config.headerHeight;

	useEffect(() => {
		document.title = "E-ISH | Not Found";
	}, []);

	if(parseInt(width)<=576) {
		fixedHeight = fixedHeight-global.config.narrowFooterHeight;
	} else {
		fixedHeight = fixedHeight-global.config.footerHeight;
	}

	return (
		<>
		<div className="main d-flex clearfix" style={{height: fixedHeight}}>
			<h4 className="not-found-message">Halaman tidak dapat ditemukan.</h4>
		</div>
    	<Footer />
    	</>
		);
}

export default NotFound;