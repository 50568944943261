// Image
import presisi from '../assets/img/logo_presisi.png';
import logo from '../assets/img/logo_intelkam.png';

// Component
import {
	Navbar,
	Nav
} from 'react-bootstrap';

function header() {
	return (
		<header id="header" className="header">
			<Navbar collapseOnSelect expand="lg" className="navbar-expand-lg navbar-dark navbar-fixed-top" variant="dark">
				<Navbar.Brand href="/">
					<img
					src={presisi}
					alt="presisi"
					/>
					<img
					src={logo}
					alt="logo"
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="ms-auto">
						<Nav.Link href="/">Beranda</Nav.Link>
						<Nav.Link href="/prosedur">Prosedur Izin</Nav.Link>
						<Nav.Link href="/landasan">Landasan Hukum</Nav.Link>
						<Nav.Link href="https://pub-eish-dev.keponet.com/" className="login-button">Login</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</header>
		);
}

export default header;