// Icon2
import iconPlaystore from '../assets/img/icon_playstore.png';
import iconAppstore from '../assets/img/icon_appstore.png';

function footer() {
	return (
		<div id="footer" className="footer clearfix">
			<div className="top">
				<div className="container">
					<ul className="footer-nav col-lg-6 col-md-6 col-sm-12">
						<li>
							<a href="/ketentuan">Syarat & Ketentuan</a>
						</li>
						<li>
							<a href="/privasi">Kebijakan Privasi</a>
						</li>
						<li>
							<a href="/kontak">Kontak Kami</a>
						</li>
						<li>
							<a href="/faq">FAQ</a>
						</li>
					</ul>
					<div className="col-lg-6 col-md-6 col-sm-12 store-link-container">
						<h4>Download E-ISH</h4>
						<ul className="store-link">
							<li>
								<a href="#">
									<img src={iconPlaystore} alt="playstore"/>
								</a>
							</li>
							<li>
								<a href="#">
									<img src={iconAppstore} alt="appstore"/>
								</a>
							</li>
						</ul>
					</div>					
				</div>
			</div>
			<div className="bottom">
				<div className="container">
					<p>Copyrights &copy; 2022 All Rights Reserved</p>
				</div>
			</div>
		</div>
		);
}

export default footer;