import { Navigate, useRoutes } from 'react-router-dom';

// Layout
import Layout from './layout/layout';

// Pages
import NotFound from './page/404';
import Home from './page/home';
import Prosedur from './page/prosedur';
import Landasan from './page/landasan';
import Register from './page/register';
import LandasanDocument from './page/landasanDocument';
import Privasi from './page/privasi';
import Ketentuan from './page/ketentuan';
import Faq from './page/faq';
import ProsedurDetail from './page/prosedurDetail';
import Kontak from './page/kontak';
import Post from './page/post';

// ----------------------------------------------------------------------

export default function Router() {
	return useRoutes([
	{
		path: '/',
		element: <Layout />,
		children: [
			{ element: <Navigate to="/" replace /> },
			{ path: '/', element: <Home /> }
		]
	},
	{
		path: '/prosedur',
		element: <Layout />,
		children: [
			{ element: <Navigate to="/prosedur" replace /> },
			{ path: '/prosedur', element: <Prosedur /> },
			{ path: 'register', element: <Register /> },
			{ path: ':imagename', element: <ProsedurDetail /> }
		]
	},
	{
		path: '/landasan',
		element: <Layout />,
		children: [
			{ element: <Navigate to="/landasan" replace /> },
			{ path: '/landasan', element: <Landasan /> },
			{ path: '/landasan/:filename', element: <LandasanDocument /> }
		]
	},
	{
		path: '/privasi',
		element: <Layout />,
		children: [
			{ element: <Navigate to="/privasi" replace /> },
			{ path: '/privasi', element: <Privasi /> }
		]
	},
	{
		path: '/ketentuan',
		element: <Layout />,
		children: [
			{ element: <Navigate to="/ketentuan" replace /> },
			{ path: '/ketentuan', element: <Ketentuan /> }
		]
	},
	{
		path: '/faq',
		element: <Layout />,
		children: [
			{ element: <Navigate to="/faq" replace /> },
			{ path: '/faq', element: <Faq /> }
		]
	},
	{
		path: '/kontak',
		element: <Layout />,
		children: [
			{ element: <Navigate to="/kontak" replace /> },
			{ path: '/kontak', element: <Kontak /> }
		]
	},
	{
		path: '/post',
		element: <Layout />,
		children: [
			{ element: <Navigate to="/post" replace /> },
			{ path: '/post/:id', element: <Post /> }
		]
	},
	{
		path: '/',
		element: <Layout />,
		children: [
			{ path: '404', element: <NotFound /> },
			{ path: '*', element: <Navigate to="/404" /> }
		]
	},
	{ path: '*', element: <Navigate to="/404" replace /> }
	]);
}
