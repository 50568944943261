// import axios from "axios";
import React, { useEffect } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

// Component
import Footer from '../layout/footer';
import PostList from '../page/postList';

// Image
import imgLandasan from '../assets/img/icon_aman.svg';
import imgProsedur from '../assets/img/icon_prosedur.svg';
import imgCepat from '../assets/img/icon_cepat.svg';
import imgEfisiensi from '../assets/img/icon_efisiensi.svg';
import imgAman from '../assets/img/icon_aman.svg';
import imgTransparan from '../assets/img/icon_transparan.svg';
import imgKepastian from '../assets/img/icon_kepastian.svg';
import imgContactless from '../assets/img/icon_contactless.svg';
import imgPribadi from '../assets/img/icon_pribadi.svg';
import imgInstansi from '../assets/img/icon_instansi.svg';
import imgPerbakin from '../assets/img/icon_perbakin.svg';

function Home() {
	const { height, width } = useWindowDimensions();
	let bannerHeight = height;
	
	useEffect(() => {
		document.title = "E-ISH | Beranda"

	}, []);

	if(parseInt(width)<=576) {
		bannerHeight = 'auto';
	}
	return (
		<>
		<div className="main has-fullbanner clearfix">

			<div className="banner col-lg-12 clearfix" style={{height: bannerHeight}}>
				<div className="banner-content">
					<div className="right-content col-md-6 col-sm-12">
						<div className="text-container">
							<h1>
								<strong>E-ISH</strong>
							</h1>
							<div className="text">
								<span className="bolded">E-ISH</span> adalah singkatan dari <span className="italicized">Elektronik – Izin Senjata Api dan Bahan Peledak.</span> E-ISH merupakan aplikasi atau sistem informasi online yang dirancang untuk mengorganisir dan mengatur peredaran senjata api non-organik dan bahan peledak yang akan dan atau sudah beredar di Negara Kesatuan Republik Indonesia; membantu tugas kepolisian dalam mengadministrasi senjata api dan bahan peledak yang akan dan atau sudah beredar di Negara Kesatuan Republik Indonesia; serta membantu pengguna umum dan atau perusahaan berbadan hukum yang terkait dalam melakukan perizinan yang berkaitan dengan senjata dan bahan peledak.
							</div>
						</div>
					</div>
					<div className="left-content col-md-6 col-sm-12">
						<div className="home-link-container">
							<a href="/prosedur" className=" text-center">
								<img src={imgProsedur} alt="prosedur izin"/>
								<p>Prosedur Izin</p>
							</a>
							<a href="/landasan" className=" text-center">
								<img src={imgLandasan} alt="landasan hukum"/>
								<p>Landasan Hukum</p>
							</a>
						</div>
						<div className="banner-text col-lg-12 mx-auto my-auto">
							<h3>Ajukan Izin Secara Online.<br/> "Hemat waktu berharga Anda"</h3>
						</div>
						<a href="/prosedur/register" className="register-button">DAFTAR SEKARANG</a>
					</div>
				</div>
			</div>

			<div className="home-mid col-lg-12">
				<div className="container">
					<h3>Dengan Menggunakan Aplikasi <span className="bolded">E-ISH</span><br/> Berikut <span className="bolded">Komitmen POLRI</span> dalam Proses Pengurusan Perizinan</h3>
					<ul className="home-benefit col-lg-12 clearfix">
						<li className="col-lg-4 col-md-6 col-sm-12 clearfix">
							<div className="benefit-container">
								<h4><span className="bolded">Efisiensi</span></h4>
								<img src={imgEfisiensi} alt="efisiensi"/>
								<p>Proses pengurusan yang tadinya dilakukan manual akan beralih ke proses digital online. Hal ini membuat pengurusan izin menjadi lebih efisien dari segi waktu, biaya, dan tenaga karena pemohon tidak perlu bolak balik ke lokasi pengajuan perizinan</p>
								<p className="home-line-container"><b className="home-line">&nbsp;</b></p>
							</div>
						</li>
						<li className="col-lg-4 col-md-6 col-sm-12 clearfix">
							<div className="benefit-container">
								<h4><span className="bolded">Cepat</span></h4>
								<img src={imgCepat} alt="cepat"/>
								<p>Proses pengurusan dan pendistribusian perizinan akan menjadi lebih cepat dan akurat bila dilakukan secara online. Anda dapat melakukan pengajuan perizinan dimanapun, kapanpun</p>
								<p className="home-line-container"><b className="home-line">&nbsp;</b></p>
							</div>
						</li>
						<li className="col-lg-4 col-md-6 col-sm-12 clearfix">
							<div className="benefit-container">
								<h4><span className="bolded">Transparan</span></h4>
								<img src={imgTransparan} alt="transparan"/>
								<p>Pengurusan perizinan menjadi lebih terbuka dan dapat dipantau kapanpun, dimanapun bagi semua pihak yang berkepentingan. Hal ini dapat meminimalisir terjadinya manipulasi data yang dapat terjadi</p>
								<p className="home-line-container"><b className="home-line">&nbsp;</b></p>
							</div>
						</li>
						<li className="col-lg-4 col-md-6 col-sm-12 clearfix">
							<div className="benefit-container">
								<h4><span className="bolded">Aman</span></h4>
								<img src={imgAman} alt="aman"/>
								<p>Berkas Pemohon aman dari kerusakan dan tersimpan dengan baik di media penyimpanan cloud. Pengenkripsian data yang terjadi akan menambah keamanan berkas yang Anda unggah di E-ISH</p>
								<p className="home-line-container"><b className="home-line">&nbsp;</b></p>
							</div>
						</li>
						<li className="col-lg-4 col-md-6 col-sm-12 clearfix">
							<div className="benefit-container">
								<h4><span className="bolded">Kepastian</span></h4>
								<img src={imgKepastian} alt="kepastian"/>
								<p>Berkas Pemohon yang masuk akan segera di follow up oleh pihak terkait sesuai antrian berkas yang masuk, sehingga berkas pemohon akan segera mendapatkan kepastian</p>
								<p className="home-line-container"><b className="home-line">&nbsp;</b></p>
							</div>
						</li>
						<li className="col-lg-4 col-md-6 col-sm-12 clearfix">
							<div className="benefit-container">
								<h4><span className="bolded">Contactless</span></h4>
								<img src={imgContactless} alt="contactless"/>
								<p>Pemohon tidak perlu sering datang ke lokasi pengajuan perizinan. Pemohon hanya perlu datang ketika ada undangan resmi dari pihak berwenang. Dengan pengajuan online, Anda akan lebih aman dari penularan penyakit</p>
								<p className="home-line-container"><b className="home-line">&nbsp;</b></p>
							</div>
						</li>
					</ul>
				</div>
			</div>


			<div className="home-mid-extra col-lg-12">
				<div className="container">
					<h3>Untuk Siapa E-ISH ?</h3>
					<p className="subtitle">Secara garis besar, E-ISH diperuntukkan bagi masyarakat sipil atau perusahaan berbadan hukum <br />yang berkepentingan dengan perizinan senjata api dan bahan peledak.</p>
					<ul className="home-user mx-auto col-lg-8 clearfix">
						<li className="col-lg-4 col-md-4 col-sm-12">
							<div className="home-user-content">
								<img src={imgPribadi} alt="pemohon pribadi"/>
								<h4>Pribadi</h4>
							</div>
						</li>
						<li className="col-lg-4 col-md-4 col-sm-12">
							<div className="home-user-content">
								<img src={imgInstansi} alt="pemohon instansi"/>
								<h4>Instansi</h4>
							</div>
						</li>
						<li className="col-lg-4 col-md-4 col-sm-12">
							<div className="home-user-content">
								<img src={imgPerbakin} alt="pemohon perbakin"/>
								<h4>Perbakin</h4>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<PostList/>

		</div>
    	<Footer />
    	</>
		);
}

export default Home;