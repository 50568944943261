import Router from './routes';
// import 'bootstrap/dist/css/bootstrap.min.css'
// import $ from 'jquery/dist/jquery.min.js'
// import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/App.css';
import './assets/css/style.css';
// Component
import Header from './layout/header';

function App() {
  return (
    <>
    <Header />
    <Router />
    </>
  );
}

export default App;
