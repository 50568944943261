import { useEffect } from 'react';

// Component
import Footer from '../layout/footer';

function Privasi() {

	useEffect(() => {
		document.title = "E-ISH | Kebijakan Privasi"
	}, []);

	return (
		<>
		<div className="main clearfix">

			<div className="privasi-banner col-lg-12 d-flex">
				<div className="container mx-auto my-auto col-sm-12 fix-float-left">
					<h3>Kebijakan Privasi</h3>
				</div>
			</div>

			<div className="privasi-content">
				<div className="container">

					<h4>Kebijakan Privasi Untuk Website</h4>
					<h4>Elektronik - Izin Senjata Api Dan bahan Peledak (E-ISH)</h4>
					&nbsp;
					<p>Kebijakan Privasi berikut ini menjelaskan bagaimana Kami memperoleh, mengumpulkan, menggunakan, menyimpan, mengungkapkan, dan menghapus Informasi Pengguna yang mengakses situs website E-ISH dengan alamat <a href="https://e-ish.com">https://e-ish.com</a>.</p>
					<p>Kami berkomitmen untuk melindungi privasi Anda karena kami menganggap privasi pengunjung situs website E-ISH sangatlah penting. Dokumen Kebijakan Privasi ini memberikan Anda sebuah informasi mengenai bagaimana Kami mengumpulkan, mencatat, menggunakan, dan melindungi informasi yang Anda berikan dan/atau dikumpulkan oleh E-ISH. Kebijakan Privasi ini dapat diperbarui dari waktu ke waktu dengan alasan apapun dan setiap versi terbaru akan berlaku saat tanggal ditetapkan. Kami akan memberi tahu Anda tentang perubahan materi apa pun pada Kebijakan Privasi Kami dengan memposting Kebijakan Privasi baru di Situs Kami. Anda disarankan untuk membaca Kebijakan Privasi ini secara teratur untuk setiap perubahan. Dengan menggunakan Situs dan Layanan kami, Anda menyetujui pemrosesan yang Kami lakukan atas informasi Anda sebagaimana ditetapkan dalam Kebijakan Privasi ini sekarang dan sebagaimana telah diubah oleh Kami.</p>

					<p className="text-title">Informasi Pribadi</p>
					<p>Informasi pribadi adalah Informasi yang dapat digunakan untuk mengidentifikasi atau menghubungi seseorang atau badan usaha. Contoh informasi pribadi termasuk nama pribadi atau nama perusahaan, nomor telepon, dan alamat email.</p>

					<p className="text-title">Pengumpulan Informasi Pribadi</p>
					<p>Kami mengumpulkan Informasi Pribadi ketika Anda memilih untuk memberikan informasi tersebut kepada Kami, seperti ketika Anda mendaftar atau menggunakan layanan apa pun yang berhubungan dengan E-ISH; meminta informasi tambahan dari kami tentang fitur dan layanan Kami; atau mengirimkan email kepada Kami. Kami juga mengumpulkan Informasi Pribadi sehubungan dengan penyediaan fitur dan layanan Kami, termasuk pemeliharaan dan hal pendukungnya. Tujuan pengumpulan informasi ini adalah untuk memberikan data statistik penggunaan, menganalisa tren, mengelola situs, melacak pergerakan pengguna di situs web ketika Anda menggunakan situs website E-ISH.</p>

					<p className="text-title">Penggunaan Informasi Pribadi</p>
					<p>Secara umum, Kami tidak berniat menggunakan informasi pribadi Anda. Kami hanya menggunakan data pribadi Anda untuk tujuan tertentu. Informasi Anda dapat digunakan kepada Kami untuk memberi Anda informasi, fitur, atau layanan yang Anda minta. Data statistik yang Anda hasilkan saat menggunakan situs E-ISH juga merupakan jenis informasi yang mungkin Kami gunakan untuk pengembangan infromasi di dalam situs E-ISH. Kami dapat menggunakan Informasi Pribadi untuk tujuan internal seperti membantu penggunaan, pengembangan, penyampaian, dan peningkatan kualitas layanan, konten, dan mungkin iklan atau pesan himbauan ke masyarakat.</p>

					<p className="text-title">Cookies</p>
					<p>Seperti situs web lainnya, <a href="https://e-ish.com">https://e-ish.com</a> menggunakan "Cookie". Cookie digunakan untuk menyimpan informasi seperti preferensi pengunjung dan halaman yang diakses atau dikunjungi pengunjung pada situs web ini. Informasi tersebut kami gunakan untuk mengoptimalkan pengalaman pengguna dengan menyesuaikan konten halaman web Kami.</p>

					<p className="text-title">Kebijakan Privasi Pihak Ketiga</p>
					<p>Kebijakan Privasi yang terdapat di <a href="https://e-ish.com">https://e-ish.com</a> tidak berlaku untuk pengiklan atau situs web lain. Oleh karena itu, Kami menyarankan Anda untuk membaca seksama masing-masing Kebijakan Privasi dari pihak ketiga untuk informasi yang lebih rinci dan menghindari resiko yang tidak diinginkan. Anda juga memiliki hak untuk menonaktifkan cookies pada browser Anda.</p>

					<p className="text-title">Mengubah atau Menghapus Informasi</p>
					<p>Jika Anda memutuskan tidak ingin lagi menerima informasi atau komunikasi semacam itu dari Kami, harap ikuti instruksi berhenti berlangganan yang disediakan dengan menghubungi pihak layanan pelanggan E-ISH melalui media komunikasi apa pun. Kami juga tidak akan pernah menjual Informasi Pribadi Anda kepada pihak ketiga manapun. Jika Anda adalah pengguna terdaftar, Anda dapat mengakses dan memperbarui atau memperbaiki informasi yang Anda berikan Kepada kami dengan mengirim email kepada kami di <a href="mailto:supporthelp@eish.com">supporthelp@eish.com</a>.</p>

					<p className="text-title">Transparansi Informasi</p>
					<p>Kami sangat berkomitmen untuk memberikan prinsip transparansi kepada setiap pengguna situs web Kami. Jika Anda ingin meninjau informasi apa pun yang telah Anda kirimkan kepada kami, silakan hubungi kami dengan mengirim email kepada kami di <a href="mailto:supporthelp@eish.com">supporthelp@eish.com</a>.</p>

					<p className="text-title">Penyedia Jasa</p>
					<p>Kami melibatkan pihak ketiga terpercaya tertentu untuk menjalankan fungsi dan memberikan layanan kepada Kami, termasuk tanpa batasan hosting dan pemeliharaan, hubungan pelanggan, penyimpanan, dan manajemen database. Kami tidak akan membagikan informasi pribadi Anda dengan pihak ketiga ini, dan hanya sesuai dengan kewajiban kontrak yang mengikat yang mengharuskan pihak ketiga tersebut untuk menjaga privasi dan keamanan data Anda.</p>

					<p className="text-title">Transfer Informasi Pribadi</p>
					<p>Dalam beberapa kasus, informasi pribadi Anda mungkin dibagikan ke pihak lain. Tapi, itu hanya akan terjadi dalam peristiwa yang sangat spesifik seperti ketika ada penyalahgunaan atau tindakan kriminal yang membuat pihak berwenang dalam payung undang-undang yang berlaku di Negara Kesatuan Republik Indonesia membutuhkan informasi tersebut untuk Kami berikan. Setiap permintaan oleh hukum harus dilengkapi dengan prosedur otoritatif.</p>

					<p className="text-title">Kepatuhan terhadap Hukum dan Penegakan Hukum</p>
					<p>Tim E-ISH bekerja sama dengan pemerintah dan aparat penegak hukum untuk menegakkan dan mematuhi hukum yang berlaku di Negara Kesatuan Republik Indonesia. Kami dapat mengungkapkan informasi apa pun tentang Anda kepada pejabat pemerintah atau penegak hukum, untuk mencegah atau menghentikan aktivitas ilegal, tidak etis, atau yang dapat ditindaklanjuti secara hukum, atau untuk mematuhi hukum serta segala aktivitas yang dapat mengganggu keamanan publik atau siapa pun.</p>

					<p className="text-title">Keamanan</p>
					<p>Kami sangat memperhatikan menjaga kerahasiaan informasi pribadi Anda. Kami menerapkan tindakan administratif fisik dan elektronik yang dirancang untuk melindungi informasi Anda dari akses yang tidak sah. Kami akan membuat pengungkapan yang diwajibkan secara hukum dari setiap pelanggaran keamanan, kerahasiaan, atau integritas data pribadi Anda yang disimpan secara elektronik yang tidak terenkripsi kepada Anda melalui email dalam waktu sesingkat mungkin dan tanpa penundaan yang tidak wajar, sesuai dengan kebutuhan yang sah dari penegakan hukum; atau tindakan apa pun yang diperlukan untuk menentukan cakupan pelanggaran dan memulihkan integritas yang wajar dari sistem data.</p>

					<p className="text-title">Persetujuan dan Perubahan Kebijakan Privasi</p>
					<p>Dengan menggunakan situs web E-ISH, Anda dengan ini menyetujui syarat-syarat penggunaan sesuai dengan ketentuan-ketentuan didalamnya. Kami sewaktu ke waktu dapat melakukan perubahan dan/ atau pembaharuan Kebijakan Privasi Kami tanpa pemberitahuan ke Anda sebelumnya. Oleh karena itu, Anda bertanggung jawab untuk mengakses Kebijakan Privasi Kami secara berkala untuk mendapatkan informasi terkini termasuk namun tidak terbatas pada perubahan dan/ atau pembaharuan yang terdapat pada Kebijakan Privasi ini. Kami juga menyimpan versi sebelumnya dari Kebijakan Privasi ini dalam arsip untuk dapat Anda tinjau kapanpun.</p>

					<p className="text-title">Kontak Kami</p>
					<p>Jika ada keraguan atau pertanyaan tentang Kebijakan Privasi ini, silahkan hubungi Kami di <a href="mailto:supporthelp@eish.com">supporthelp@eish.com</a>. Kebijakan Privasi ini diperbarui pada: 23 Mei 2021 dan akan berlaku kepada seluruh Pengguna situs web E-ISH bersamaan dengan tanggal tersebut.</p>
				
				</div>				
			</div>

		</div>
    	<Footer />
    	</>
		);
	
}

export default Privasi;