import React, { useEffect } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
// Component
import Footer from '../layout/footer';

function LandasanDocument() {
	const url = new URL(window.location);
	const docUrl = url.pathname.replaceAll('/', '').replace('landasan', '');
	const doc = '../doc/'+docUrl+'.pdf';
	const { height } = useWindowDimensions();

	useEffect(() => {
		document.title = "E-ISH | Landasan Hukum"
	}, []);

	return (
		<>
		<div className="main clearfix">

			<div className="landasan-banner col-lg-12 d-flex">
				<div className="container mx-auto my-auto col-sm-12 fix-float-left">
					<h3>Landasan Hukum</h3>
				</div>
			</div>

			<div className="landasan-document-content">
				<div className="container">
					<iframe src={doc} title="document" className="col-lg-12" style={{height:height}}/>
				</div>
			</div>

		</div>
    	<Footer />
    	</>
		);
	
}

export default LandasanDocument;