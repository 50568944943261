import { useEffect } from 'react';

// Component
import Footer from '../layout/footer';

// Component
import {
	Accordion
} from 'react-bootstrap';

// Image

function Prosedur() {

	useEffect(() => {
		document.title = "E-ISH | Prosedur Izin"
	}, []);

	return (
		<>
		<div className="main clearfix">

			<div className="prosedur-banner col-lg-12 d-flex">
				<div className="container mx-auto my-auto col-sm-12 fix-float-left">
					<h3>Prosedur Izin</h3>
				</div>
			</div>

			<div className="prosedur-mid col-lg-12">
				<div className="container">
					<h3>Berikut lorem ipsum bla ... bla ...</h3>
					<Accordion defaultActiveKey="0">
						<Accordion.Item eventKey="0">
							<Accordion.Header>Izin Senjata Api Beladiri</Accordion.Header>
							<Accordion.Body>
								<ul>
									<li>
										<a href="/prosedur/pemilikan-bela-diri">Permohonan Izin Pemilikan dan Penggunaan Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/perpanjangan-bela-diri">Permohonan Perpanjangan Izin Pemilikan dan Penggunaan Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/hibah-bela-diri">Permohonan Hibah Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/mutasi-bela-diri">Permohonan Izin Mutasi Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/pemusnahan-bela-diri">Permohonan Izin Pemusnahan Senjata Api Beladiri</a>
									</li>
								</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header>Izin Senjata Api Olahraga</Accordion.Header>
							<Accordion.Body>
								<ul>
									<li>
										<a href="/prosedur/pemilikan-olah-raga">Permohonan Izin Pemilikan Senjata Api Olahraga</a>
									</li>
									<li>
										<a href="/prosedur/perpanjangan-olah-raga">Permohonan Perpanjangan Izin Pemilikan Senjata Api Olahraga</a>
									</li>
									<li>
										<a href="/prosedur/hibah-olah-raga">Permohonan Hibah Senjata Api Olahraga</a>
									</li>
									<li>
										<a href="/prosedur/mutasi-olah-raga">Permohonan Izin Mutasi Senjata Api Olahraga</a>
									</li>
									<li>
										<a href="/prosedur/pemusnahan-olah-raga">Permohonan Izin Pemusnahan Senjata Api Olahraga</a>
									</li>
								</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header>Izin Senjata Api Instansi</Accordion.Header>
							<Accordion.Body>
								<ul>
									<li>
										<a href="/prosedur/impor-bela-diri">Permohonan Izin Impor Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/pembelian-bela-diri">Permohonan Izin Pembelian Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/pemilikan-bela-diri">Permohonan Izin Pemilikan Baru dan Perpanjangan Kepemilikan Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/hibah-bela-diri">Permohonan Hibah Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/mutasi-bela-diri">Permohonan Izin Mutasi Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/pemusnahan-bela-diri">Permohonan Izin Pemusnahan Senjata Api Beladiri</a>
									</li>
								</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header>Izin Senjata Api Importir</Accordion.Header>
							<Accordion.Body>
								<ul>
									<li>
										<a href="/prosedur/impor-bela-diri">Permohonan Izin Impor Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/pembelian-bela-diri">Permohonan Izin Pembelian Senjata Api Beladiri</a>
									</li>
									<li>
										<a href="/prosedur/impor-olah-raga">Permohonan Izin Impor Senjata Api Olahraga</a>
									</li>
									<li>
										<a href="/prosedur/pembelian-olah-raga">Permohonan Izin Pembelian Senjata Api Olahraga</a>
									</li>
									<li>
										<a href="/prosedur/ekspor">Permohonan Izin Ekspor Senjata Api</a>
									</li>
								</ul>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
			</div>

			<div className="prosedur-register col-lg-12">
				<div className="container clearfix">
					<h3>Kesulitan membuat akun E-ISH?</h3>
					<a href="/prosedur/register" className="btn btn-primary center-button">Cara Buat Akun</a>
				</div>
			</div>

			<div className="prosedur-dokumen col-lg-12">
				<div className="container clearfix">
					<h3>Persyaratan Dokumen</h3>
					<p>Persyaratan dokumen terkait dengan perizinan yang berkaitan dengan senjata api dan bahan peledak</p>
					<ul className="clearfix prosedur-dokumen-list">
						<li className="prosedur-dokumen-item col-lg-4 col-md-12">
							<div className="dokumen-list-container">
								<h4 style={{backgroundColor: '#0c8732'}}>
									Beladiri & Koleksi
								</h4>
								<ol>
									<li>KTP</li>
									<li>KK</li>
									<li>Akte Kelahiran</li>
									<li>Surat keterangan sehat jasmani dan rohani dari dokter polri</li>
									<li>Surat keterangan psikologisdari psikolog polri</li>
									<li>SKCK</li>
									<li>Surat keterampilan menembak</li>
									<li>Surat Izin Usaha Perdagangan (SIUP) atau Akte Pendirian Perusahaan (optional utk swasta)</li>
									<li>Surat Keputusan Pangkat/Jabatan atau Surat Keterangan (Sket)</li>
								</ol>
								<p className="prosedur-button-container">
									<a href="#" className="prosedur-button">Lihat Detail</a>
								</p>
							</div>
						</li>
						<li className="prosedur-dokumen-item col-lg-4 col-md-12">
							<div className="dokumen-list-container">
								<h4 style={{backgroundColor: '#ea6706'}}>
									Olahraga
								</h4>
								<ol>
									<li>KTP</li>
									<li>Akte Kelahiran</li>
									<li>KK</li>
									<li>Surat Jalan/Rekomendasi Perbakin</li>
									<li>Surat keterangan sehat jasmani dan rohani dari dokter polri</li>
									<li>Surat keterangan psikologisdari psikolog polri</li>
									<li>SKCK</li>
									<li>Surat keterampilan menembak</li>
									<li>Surat Izin Usaha Perdagangan (SIUP) atau Akte Pendirian Perusahaan (optional utk swasta)</li>
									<li>Surat Keputusan Pangkat/Jabatan atau Surat Keterangan (Sket)</li>
								</ol>
								<p className="prosedur-button-container">
									<a href="#" className="prosedur-button">Lihat Detail</a>
								</p>
							</div>
						</li>
						<li className="prosedur-dokumen-item col-lg-4 col-md-12">
							<div className="dokumen-list-container">
								<h4 style={{backgroundColor: '#e81f8d'}}>
									Airsoft Gun & Paintball
								</h4>
								<ol>
									<li>KTP</li>
									<li>KK</li>
									<li>Surat keterangan sehat jasmani dan rohani dari dokter polri</li>
									<li>Surat keterangan psikologisdari psikolog polri</li>
									<li>SKCK</li>
									<li>Sertifikat menembak dari induk organisasi</li>
									<li>Fotocopy KTA klub menembak</li>
									<li>Fotokopi surat izin impor/pembelian/hibah/surat keterangan asal usul (untuk pemilikan baru)</li>
									<li>Pas foto</li>
									<li>Scan buku pas (surat ijin pemilikan) lama</li>
								</ol>
								<p className="prosedur-button-container">
									<a href="#" className="prosedur-button">Lihat Detail</a>
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>

		</div>
    	<Footer />
    	</>
		);
	
}

export default Prosedur;