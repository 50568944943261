// 
import React, { useState, useEffect } from 'react';

function ShortPost(props) {
	const item = props.base;
	const postWidth = props.postWidth;
	const [fulldate, setFd] = useState('');
	const month = global.config.month;

	useEffect(() => {

		let dateStr = '';
		const baseDate = new Date(item.date);

		dateStr += baseDate.getDate()+' ';
		dateStr += month[baseDate.getMonth()]+' ';
		dateStr += baseDate.getFullYear();

		setFd(dateStr);

	}, []);

	if(typeof item._embedded['wp:featuredmedia']!= 'undefined') {
		return (
			<div className="home-post-container" style={{width: postWidth+'%'}} key={item.id}>
				<a href={'/post/'+item.id}>
				<div className="home-post col-lg-12 clearfix">
					<div className="col-lg-4 col-md-4 col-sm-12">
						<img src={item._embedded['wp:featuredmedia'][0].source_url} alt="post featured"/>
					</div>
					<div className="home-post-content col-lg-8 col-md-8 col-sm-12">
						<h5>{item.title.rendered}</h5>
						<p>{fulldate}</p>
					</div>
				</div>
				</a>
			</div>
		);
	} else {
		return (
			<div className="home-post-container" style={{width: postWidth+'%'}} key={item.id}>
				<a href={'/post/'+item.id}>
				<div className="home-post col-lg-12 clearfix">
					<div className="home-post-content col-lg-12">
						<h5>{item.title.rendered}</h5>
						<p>{fulldate}</p>
					</div>
				</div>
				</a>
			</div>
		);
	}
}

export default ShortPost;