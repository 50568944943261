import axios from "axios";
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

// Component
import Footer from '../layout/footer';

function Post() {
	const url = new URL(window.location);
	const id = parseInt(url.pathname.replaceAll('/', '').replace('post', ''));
	const [post, setPost] = useState([]);

	useEffect((id) => {
		axios({
			method: 'GET',
			url: global.config.sourceurl+'/wp/v2/posts/'+this.id+'?_embed'
		}).then((res) => {
			const result = res.data;
			setPost([result]);
		});
	}, []);

	if(post.length>0) {
		console.log(post[0]._embedded['wp:featuredmedia'][0].source_url);
	document.title = "E-ISH | "+post[0].title.rendered;
	return (
		<>
		<div className="main clearfix">

			<div className="privasi-banner col-lg-12 d-flex" style={{backgroundImage: "url("+post[0]._embedded['wp:featuredmedia'][0].source_url+")", backgroundPosition: "top right", backgroundSize: "contain"}}>
				<div className="container mx-auto my-auto col-sm-12 fix-float-left">
					<h3>{post[0].title.rendered}</h3>
				</div>
			</div>

			<div className="privasi-content">
				<div className="container">
					{ReactHtmlParser(post[0].content.rendered)}
				</div>				
			</div>

		</div>
    	<Footer />
    	</>
		);
	} else {
		return (
			<div className="main-content d-flex" style={{minHeight: '100%', minWidth: '100%'}}>
				<div className="mx-auto my-auto">
					<div className="spinner-border"></div>
				</div>
			</div>
			);
	}
	
}

export default Post;